import _ from "lodash";
import qs from "qs";
import axios from "axios";

function menuTypeUrl(options) {
  const query = options.query;
  const format = options.format;
  let path;
  if (format == "design") {
    path = "/view";
  } else if (format == "webmenu") {
    path = "/view-no-design";
  } else if (format == "details" || format == "details-all") {
    path = "/details-calendar";
  } else if (format === "details-alpha") {
    path = "/details-calendar-alpha";
  }

  if (format === "mobile") {
    return `/mobile/#/tab/menuItems///${options.query.menuType}`;
  }

  const queryStringified = {
    ...options.query,
    showAllNutrients: format === "details-all"
  };

  return `${_SNAF_URL_}/webmenus2#${path}?${qs.stringify(queryStringified)}`;
}

export default async (menuTypeID, options = {}) => {
  const result = await axios({
    method: "POST",
    url: `${_BACKEND_URL_}/graphql`,
    params: {
      query: `{
                menuType(id:"${menuTypeID}") {
                    name
                    formats
                    defaultPublishedMonth {
                        id
                    }
                }
            }`
    }
  });
  const menuType = _.get(result, "data.data.menuType");
  const id = _.get(menuType, "defaultPublishedMonth.id");
  if (!id) {
    alert(
      `Sorry the district has not published any menus for
      ${menuType.name}, please check back soon!`
    );
    return;
  }
  const query = {
    id,
    menuType: menuTypeID,
    siteCode: options.siteCode,
    siteCode2: options.siteCode2
  };

  if (options.date) {
    query.date = options.date;
  }

  let format = options.format;

  // If menu is not available in design view, ensure they don't end up on design view!
  // for details, details-all, those are available always, hence we only run this logic
  // if the requested format is webmenu / design.
  if (["webmenu", "design"].indexOf(format) !== -1) {
    if (!menuType.formats.length) {
      // If they dont have formats setup, just show design.
      format = "design";
    } else if (-1 === menuType.formats.indexOf(format)) {
      // If there are formats, use one of those
      format = menuType.formats[0];
    }
  }

  if (options.newWindow) {
    const location = menuTypeUrl({ query, format });
    window.open(location, "_blank");
  } else {
    window.location = menuTypeUrl({ query, format });
  }
};
