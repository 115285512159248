import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

const styles = {
  container: {
    fontFamily: 'arial',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 1.6,
  },
  h2: {
    marginBottom: 8,
  },
};

export default class HarvestOfTheMonth extends Component {
  render() {
    const month = moment()
      .format('MMM')
      .toLowerCase();
    const monthFull = moment().format('MMMM');
    return (
      <div style={styles.container}>
        <h2 style={styles.h2}>Harvest Of The Month</h2>
        <a href={`https://docs.isitesoftware.com/snaf-assets/data/pdf/harvestofmonth/${month}_en.pdf`} target="_new">
          {monthFull} - English
        </a>
        <br />
        <a href={`https://docs.isitesoftware.com/snaf-assets/data/pdf/harvestofmonth/${month}_sp.pdf`} target="_new">
          {monthFull} - Spanish
        </a>
        <br />
        <br />
      </div>
    );
  }
}

const element = document.getElementById('rootHarvestOfTheMonth');
if (element) {
  ReactDOM.render(<HarvestOfTheMonth />, element);
}
