import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import graphqlify from 'graphqlify';
import BouncingArrow from './BouncingArrow';

const DO_NOT_USE = false;

const sortByName = (s1, s2) => {
  if (s1.name.trimStart() > s2.name.trimStart()) {
    return 1;
  }
  return s2.name.trimStart() > s1.name.trimStart() ? -1 : 0;
};

const Selectdiv = styled.div`
  display: flex;
  @media (max-width: 999px) {
    display: flex;
    flex-direction: column;

    i {
      transform: rotate(90deg);
      transform-origin: center;
      max-width: 24px;
    }
  }
`;

export default class ChainedSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownMenuExcludeSchools: false,
      selectedSite: props.selectedSite || '',
      selectedSiteChild: props.selectedSiteChild || '',
      selectedMenuChoice: '',
      sites: [],
      sitesChildren: [],
      menuChoices: [],
    };

    if (this.state.selectedSite) {
      this.fetchSitesChildren();
      this.fetchMenuChoices();
    }
    if (this.state.selectedMenuChoice) this.fetchMenuChoices();
  }

  componentDidMount() {
    this.fetchSites();
  }

  handleSiteChange = e => {
    this.setState(
      {
        selectedSite: e.target.value,
        selectedSiteChild: '',
        selectedMenuChoice: '',
        sitesChildren: [],
        menuChoices: [],
      },
      () => {
        if (this.state.dropdownMenuExcludeSchools) {
          this.fetchMenuChoices();
        } else {
          this.fetchSitesChildren();
        }
        if (this.props.handleSiteChange) this.props.handleSiteChange(this.state.selectedSite);
        if (this.props.handleSiteChildChange) this.props.handleSiteChildChange(this.state.selectedSiteChild);
        if (this.props.handleMenuTypeChange) this.props.handleMenuTypeChange(this.state.selectedMenuChoice);
      },
    );
  };

  handleSiteChildChange = e => {
    this.setState(
      {
        selectedSiteChild: e.target.value,
        selectedMenuChoice: '',
        menuChoices: [],
      },
      () => {
        this.fetchMenuChoices();
        if (undefined !== this.props.handleSiteChildChange)
          this.props.handleSiteChildChange(this.state.selectedSiteChild);
        if (undefined !== this.props.handleMenuTypeChange)
          this.props.handleMenuTypeChange(this.state.selectedMenuChoice);
      },
    );
  };

  handleMenuTypeChange = e => {
    const index = e.target.value;
    const choice = this.state.menuChoices[index];
    this.setState(
      {
        selectedMenuChoice: index,
      },
      () => {
        if (undefined !== this.props.handleMenuChoiceChange) this.props.handleMenuChoiceChange(choice);
      },
    );
  };

  showSitesArrow = () => DO_NOT_USE && !this.state.selectedSite;

  showSiteChildrenSelect = () => this.state.sitesChildren.length > 0;

  showSiteChildrenArrow = () => DO_NOT_USE && this.showSiteChildrenSelect() && !this.state.selectedSiteChild;

  showMenuChoiceSelect = () =>
    this.state.dropdownMenuExcludeSchools
      ? !this.showSitesArrow() && this.state.menuChoices.length > 0
      : !this.showSitesArrow() && !this.showSiteChildrenArrow() && this.state.menuChoices.length > 0;

  showMenuTypeArrow = () => DO_NOT_USE && this.showMenuChoiceSelect() && !this.state.selectedMenuChoice;

  render() {
    const arrow = <BouncingArrow color={this.props.arrowColor} />;
    return (
      <Selectdiv>
        {this.showSitesArrow() ? arrow : null}
        {this.renderSiteSelect()}

        {!this.state.dropdownMenuExcludeSchools && (
          <React.Fragment>
            {this.showSiteChildrenArrow() ? arrow : null}
            {this.showSiteChildrenSelect() ? this.renderSiteChildSelect() : null}
          </React.Fragment>
        )}

        {this.showMenuTypeArrow() ? arrow : null}
        {this.showMenuChoiceSelect() ? this.renderMenuChoiceSelect() : null}
      </Selectdiv>
    );
  }

  renderSiteSelect() {
    return (
      <select onChange={this.handleSiteChange} value={this.state.selectedSite}>
        <option value="" disabled hidden>
          Select a site group
        </option>
        <option />
        {!this.state.sites
          ? ''
          : this.state.sites.map(site => {
              return (
                <option key={site.id} value={site.id}>
                  {`${site.name.substring(0, 25)}${site.name.length > 25 ? '...' : ''}`}
                </option>
              );
            })}
      </select>
    );
  }

  renderSiteChildSelect() {
    return (
      <select onChange={this.handleSiteChildChange} value={this.state.selectedSiteChild}>
        <option value="" disabled hidden>
          Select a site
        </option>
        <option />
        {!this.state.sitesChildren
          ? ''
          : this.state.sitesChildren.sort(sortByName).map(site => {
              return (
                <option key={site.id} value={site.id}>
                  {`${site.name.substring(0, 25)}${site.name.length > 25 ? '...' : ''}`}
                </option>
              );
            })}
      </select>
    );
  }

  renderMenuChoiceSelect() {
    return (
      <select onChange={this.handleMenuTypeChange} value={this.state.selectedMenuChoice}>
        <option value="" disabled hidden>
          Select a menu
        </option>
        <option />
        {!this.state.menuChoices
          ? ''
          : this.state.menuChoices.map((menuChoice, index) => {
              return (
                <option key={index} value={index}>
                  {`${menuChoice.name.substring(0, 30)}${menuChoice.name.length > 30 ? '...' : ''}`}
                </option>
              );
            })}
      </select>
    );
  }

  async fetchSites(parentSite = null) {
    const query = `query fetchSites($organizationID: String!) {
            organization(id:$organizationID) {
                id
                name
                OnlineMenuDesignSettings {
                  dropdownMenuExcludeSchools
                }
                sites {
                    id
                    name
                }
            }
        }`;
    const variables = { organizationID: this.props.organizationID.toString() };
    const result = await axios({
      method: 'POST',
      url: `${_BACKEND_URL_}/graphql`,
      params: { query, variables: JSON.stringify(variables) },
    });
    this.setState({
      sites: result.data.data.organization.sites,
      dropdownMenuExcludeSchools: result.data.data.organization.OnlineMenuDesignSettings.dropdownMenuExcludeSchools,
    });
  }

  async fetchSitesChildren() {
    const query = `query fetchSitesChildren($siteID: String!) {
            site(depth: 0, id: $siteID) {
                sites {
                    id
                    name
                }
            }
        }`;
    const variables = { siteID: this.state.selectedSite };
    const result = await axios({
      method: 'POST',
      url: `${_BACKEND_URL_}/graphql`,
      params: { query, variables: JSON.stringify(variables) },
    });
    this.setState({ sitesChildren: result.data.data.site.sites }, () => {
      if (this.state.sitesChildren.length === 0) this.fetchMenuChoices();
    });
  }

  graphQLSiteInput = () => {
    const site = { depth_0_id: this.state.selectedSite };
    if (this.state.selectedSiteChild) site.depth_1_id = this.state.selectedSiteChild;
    return site;
  };

  async fetchMenuChoices() {
    /**
     * If they're viewing a menu type w/o a site code passed in,
     * we cannot display a menu type switcher until they select a site.
     */
    if (!this.state.selectedSite && !this.state.selectedSiteChild) return;
    const publish_location = this.props.publishLocation ? this.props.publishLocation : 'website';
    const query = graphqlify({
      menuTypes: {
        fields: {
          id: {},
          name: {},
        },
        params: {
          site: this.graphQLSiteInput(),
          publish_location,
        },
      },
      cycleMenus: {
        fields: {
          id: {},
          name: {},
        },
        params: {
          site: this.graphQLSiteInput(),
          publish_location,
        },
      },
      listMenus: {
        fields: {
          id: {},
          name: {},
          settings: {
            fields: {
              format_design: {},
              format_webmenu: {},
            },
          },
        },
        params: {
          site: this.graphQLSiteInput(),
          publish_location,
        },
      },
    });
    const result = await axios({
      method: 'POST',
      url: `${_BACKEND_URL_}/graphql`,
      params: { query },
    });
    const menuTypes = result.data.data.menuTypes.map(menuType => {
      menuType.type = 'menuType';
      return menuType;
    });
    const cycleMenus = result.data.data.cycleMenus.map(cycleMenu => {
      cycleMenu.type = 'menu';
      return cycleMenu;
    });
    const listMenus = result.data.data.listMenus.map(listMenu => {
      listMenu.type = 'menu';
      listMenu.menuType = 'list';
      return listMenu;
    });
    const menuChoices = [...menuTypes, ...cycleMenus, ...listMenus].sort(sortByName);
    let selectedMenuChoice = '';
    if (this.props.selectedMenuChoice) {
      const choiceObject = menuChoices.find(choice => {
        return choice.type === this.props.selectedMenuChoice.type && choice.id === this.props.selectedMenuChoice.id;
      });
      if (choiceObject) selectedMenuChoice = menuChoices.indexOf(choiceObject);
    }
    this.setState({ menuChoices, selectedMenuChoice });
  }
}

ChainedSelect.propTypes = {
  organizationID: PropTypes.string,
  selectedSite: PropTypes.string,
  selectedSiteChild: PropTypes.string,
  selectedMenuChoice: PropTypes.object,
  handleSiteChange: PropTypes.func,
  handleSiteChildChange: PropTypes.func,
  handleMenuChoiceChange: PropTypes.func,
  publishLocation: PropTypes.oneOf(['website', 'mobile', 'onlineordering']),
  arrowColor: PropTypes.string,
};
