import React, { Component } from "react";
import styled, { keyframes } from "styled-components";

class BouncingArrow extends Component {
  render() {
    return <i className={this.props.className + " material-icons"}>&#xE5C8;</i>;
  }
}

const bounceFrames = keyframes`from {left: -8px;} to {left: -1px;}`;

export default styled(BouncingArrow)`
  color: ${props => (props.color ? props.color : "inherit")};
  position: relative;
  margin-right: 3px;
  left: -1px;
  -webkit-animation: ${bounceFrames} 0.5s ease-in-out infinite alternate;
  animation: ${bounceFrames} 0.5s ease-in-out infinite alternate;
`;
