import _ from "lodash";
import qs from "qs";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import ChainedSelect from "./ChainedSelect";
import PropTypes from "prop-types";
import gotoMenuType from "./helpers/gotoMenuType";
import gotoMenu from "./helpers/gotoMenu";

export default class ChainedSelectWithLocation extends Component {
  constructor(props) {
    super(props);
    // AngularJS router is still used which stores in location.hash not location.search :(
    const search = qs.parse(
      window.location.hash.substring(window.location.hash.indexOf("?") + 1)
    );
    this.state = {
      selectedSite: search.siteCode,
      selectedSiteChild: search.siteCode2,
      selectedMenuChoice: {
        id: search.menuType ? search.menuType : search.id,
        type: search.menuType ? "menuType" : "menu",
        format: "design"
      }
    };
  }

  render() {
    return (
      <ChainedSelect
        {...this.props}
        selectedSite={this.state.selectedSite}
        selectedSiteChild={this.state.selectedSiteChild}
        selectedMenuChoice={this.state.selectedMenuChoice}
        handleSiteChange={this.handleSiteChange}
        handleSiteChildChange={this.handleSiteChildChange}
        handleMenuChoiceChange={this.handleMenuChoiceChange}
      />
    );
  }

  async gotoMenuType(menuType) {
    return gotoMenuType(menuType.id, {
      siteCode: this.state.selectedSite,
      siteCode2: this.state.selectedSiteChild,
      format: this.props.format ? this.props.format : "design",
      newWindow: true
    });
  }

  gotoMenu(menu) {
    gotoMenu(menu.id, {
      siteCode: this.state.selectedSite,
      siteCode2: this.state.selectedSiteChild,
      format: (() => {
        if (menu.hasOwnProperty('menuType') && menu.menuType === 'list') {
          return menu.settings.format_design ? 'design' : 'details';
        }
        return this.props.format ? this.props.format : 'design';
      })(),
    });
  }

  handleSiteChange = val => {
    this.setState(
      {
        selectedSite: val,
        selectedSiteChild: undefined,
        selectedMenuChoice: undefined,
        sitesChildren: [],
        menuChoices: []
      },
      () => {
        if (undefined !== this.props.handleSiteChange)
          this.props.handleSiteChange(this.state.selectedSite);
        if (undefined !== this.props.handleSiteChildChange)
          this.props.handleSiteChildChange(this.state.selectedSiteChild);
        if (undefined !== this.props.handleMenuTypeChange)
          this.props.handleMenuTypeChange(this.state.selectedMenuChoice);
      }
    );
  };

  handleSiteChildChange = val => {
    this.setState(
      {
        selectedSiteChild: val,
        selectedMenuChoice: undefined
      },
      () => {
        if (undefined !== this.props.handleSiteChildChange)
          this.props.handleSiteChildChange(this.state.selectedSiteChild);
        if (undefined !== this.props.handleMenuTypeChange)
          this.props.handleMenuTypeChange(this.state.selectedMenuChoice);
      }
    );
  };

  handleMenuChoiceChange = val => {
    this.setState(
      {
        selectedMenuChoice: val
      },
      () => {
        this.changeLocation();
        if (undefined !== this.props.handleMenuChoiceChange)
          this.props.handleMenuChoiceChange(val);
      }
    );
  };

  async changeLocation() {
    if (
      undefined === this.state.selectedMenuChoice ||
      !this.state.selectedMenuChoice
    )
      return;
    switch (this.state.selectedMenuChoice.type) {
      case "menuType":
        this.gotoMenuType(this.state.selectedMenuChoice);
        break;
      case "menu":
        this.gotoMenu(this.state.selectedMenuChoice);
        break;
    }
  }
}

ChainedSelectWithLocation.propTypes = {
  organizationID: PropTypes.string,
  selectedSite: PropTypes.string,
  selectedSiteChild: PropTypes.string,
  selectedMenuChoice: PropTypes.object,
  handleSiteChange: PropTypes.func,
  handleSiteChildChange: PropTypes.func,
  handleMenuChoiceChange: PropTypes.func,
  publishLocation: PropTypes.oneOf(["website", "mobile", "onlineordering"]),
  format: PropTypes.string,
  arrowColor: PropTypes.string
};

const element = document.getElementById("rootMenuDropdownPicker");
if (element) {
  ReactDOM.render(
    <ChainedSelectWithLocation organizationID={element.dataset.oid} />,
    element
  );
}
