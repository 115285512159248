import qs from "qs";
import axios from "axios";

function menuUrl(options) {
  const format = options.format;
  let path;
  if (format === "design") {
    path = "/view";
  } else if (format === "webmenu") {
    // cycles & lists do not work in "web menu" format, so force use of "design" format for now.
    path = "/view-no-design";
  } else if (format === "details" || format === "details-all") {
    path = "/details-menu";
  } else if (format === "details-alpha") {
    path = "/details-calendar-alpha";
  }

  const queryStringified = {
    ...options.query,
    showAllNutrients: format === "details-all"
  };

  return `${_SNAF_URL_}/webmenus2#${path}?${qs.stringify(queryStringified)}`;
}

export default function gotoMenu(id, options) {
  const location = menuUrl({
    format: options.format,
    query: {
      id,
      siteCode: options.siteCode,
      siteCode2: options.siteCode2,
      date: options.date,
      day: options.day,
      week: options.week
    }
  });

  if (options.newWindow) {
    window.open(location, "_blank");
  } else {
    window.location = location;
  }
}
