import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import graphqlify from 'graphqlify';
import axios from 'axios';
import uuid from 'uuid/v1';
import moment from 'moment';
import 'core-js';

const styles = {
  container: {
    fontFamily: 'Arial',
  },
  headerContainer: {
    width: 300,
    textAlign: 'center',
    marginBottom: 10,
  },
  arrowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 'bold',
  },
  arrow: {
    border: 0,
    cursor: 'pointer',
    marginBottom: 2,
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px 0',
  },
  menuItemLink: {
    fontSize: 14,
    marginLeft: 6,
  },
};

class MenuPicker extends Component {
  constructor(props) {
    super(props);
    const now = moment();

    this.state = {
      sid: props.sid,
      month: parseInt(now.format('M')),
      year: parseInt(now.format('Y')),
      menus: [],
    };
  }

  handlePreviousMonth = async () => {
    let newState = { ...this.state };
    newState.month--;
    if (newState.month < 1) {
      newState.month = 12;
      newState.year--;
    }
    await this.setState(newState);
    this.fetchMenus();
  };

  handleNextMonth = async () => {
    let newState = { ...this.state };
    newState.month++;
    if (newState.month > 12) {
      newState.month = 1;
      newState.year++;
    }
    await this.setState(newState);
    this.fetchMenus();
  };

  async fetchMenus() {
    try {
      const query = graphqlify({
        goingGreenMenus: {
          fields: {
            id: {},
            name: {},
            siteName: {},
            fileType: {},
          },
          params: {
            month: this.state.month,
            year: this.state.year,
            organizationId: this.state.sid,
          },
        },
      });

      const results = await axios({
        method: 'POST',
        url: `${_BACKEND_URL_}/graphql`,
        params: { query },
      });
      console.log('response from query', results.data);
      this.setState({ menus: results.data.data.goingGreenMenus });
    } catch (e) {
      console.error('e: ', e.message);
    }
  }

  componentDidMount() {
    this.fetchMenus();
  }

  render() {
    return (
      <div style={styles.container}>
        <Header {...this.state} handlePreviousMonth={this.handlePreviousMonth} handleNextMonth={this.handleNextMonth} />
        <MenuList {...this.state} />
      </div>
    );
  }
}

MenuPicker.propTypes = {
  sid: PropTypes.string,
};

const Header = props => {
  return (
    <div style={styles.headerContainer}>
      <div style={{ fontSize: 16, margin: '6px 0' }}>Click arrows to scroll through months</div>
      <div style={styles.arrowContainer}>
        <img
          src="https://docs.isitesoftware.com/images/resultset_previous.png"
          style={styles.arrow}
          alt="Previous Month"
          onClick={props.handlePreviousMonth}
        />
        {months[props.month - 1]} {props.year} Menus
        <img
          src="https://docs.isitesoftware.com/images/resultset_next.png"
          style={styles.arrow}
          alt="Next Month"
          onClick={props.handleNextMonth}
        />
      </div>
    </div>
  );
};

const MenuList = props => {
  if (!props.menus.length) {
    return [<p style={{ textAlign: 'center', marginTop: 28 }}>No menus published for this month</p>];
  }

  let previousSiteName = '';
  let output = [];
  props.menus.forEach((menu, i) => {
    if (!previousSiteName || previousSiteName !== menu.siteName) {
      previousSiteName = menu.siteName;
      output.push(
        <h3 style={{ textAlign: 'center' }} key={uuid()}>
          {menu.siteName}
        </h3>,
      );
    }
    output.push(<MenuItem key={i} menu={menu} sid={props.sid} />);
  });
  return output;
};

const MenuItem = props => {
  const url = `downloadMenu.php/${props.sid}/${props.menu.id}`;
  const icon = props.menu.fileType === 'x-isitesoftware/url' ? 'world.png' : 'calendar.png';
  return (
    <div style={styles.menuItem}>
      <img src={`https://docs.isitesoftware.com/images/${icon}`} />
      <a style={styles.menuItemLink} href={`${_SNAF_URL_}/${url}`} target="_blank">
        {props.menu.name}
      </a>
    </div>
  );
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const element = document.getElementById('rootMenuPicker');
if (element) {
  ReactDOM.render(<MenuPicker sid={element.dataset.oid} />, element);
}
