import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import uuid from 'uuid/v1';

const styles = {
  container: {
    fontFamily: 'arial',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 1.6,
  },
};

export default class GoingGreenMenus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sid: props.sid,
      showSignUp: true,
      siteGroups: [],
    };
  }

  handleFetchSites = async () => {
    const results = await axios({
      method: 'GET',
      url: `${_BACKEND_URL_}/districts/${this.state.sid}?filter=greenmenus_site_groups`,
    });
    const siteGroups = results.data.greenmenus_site_groups;
    this.setState({ siteGroups });
  };

  componentDidMount() {
    this.handleFetchSites();
  }

  render() {
    return (
      <div style={styles.container}>
        {this.state.showSignUp ? <GoingGreenSignUp {...this.state} /> : <GoingGreenIcon />}
      </div>
    );
  }
}

const GoingGreenIcon = () => {
  return <img src="https://www.schoolnutritionandfitness.com/images/goinggreenmenus.jpg" />;
};

const GoingGreenSignUp = props => {
  return (
    <div>
      <p>Sign up to have your student's menus &amp; newsletter emailed monthly</p>
      <form>
        <div>
          <label htmlFor="inputEmail">Email</label>
          <div>
            <input type="text" id="inputEmail" placeholder="Email" required />
          </div>
        </div>
        <SiteSelector siteGroups={props.siteGroups} />
      </form>
    </div>
  );
};

const SiteSelector = props => {
  return (
    <ul>
      {props.siteGroups.map(siteGroup => (
        <li key={uuid()}>
          <label>
            {(!siteGroup.sites || !siteGroup.sites.length) && <input type="checkbox" />}
            {siteGroup.name}
          </label>
          {siteGroup.sites.length && (
            <ul>
              {siteGroup.sites.map(site => (
                <li key={uuid()}>{site.name}</li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

const element = document.getElementById('rootGoingGreenMenus');
if (element) {
  ReactDOM.render(<GoingGreenMenus sid={element.dataset.oid} />, element);
}
